/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    hr: "hr",
    h3: "h3",
    ul: "ul",
    li: "li",
    strong: "strong",
    em: "em",
    pre: "pre",
    code: "code",
    h2: "h2"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "quick-use-cases",
    style: {
      position: "relative"
    }
  }, "Quick Use Cases", React.createElement(_components.a, {
    href: "#quick-use-cases",
    "aria-label": "quick use cases permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "| Use Case                                                  | Option(s)                     |\n| :-------------------------------------------------------- | :---------------------------- |\n| New feature announcement                                  | ", React.createElement(_components.a, {
    href: "#announcement"
  }, "Announcement"), " |\n| System wide error message                                 | ", React.createElement(_components.a, {
    href: "#announcement"
  }, "Announcement"), " |\n| Confirmation of Deletion on critical action               | ", React.createElement(_components.a, {
    href: "#dialog"
  }, "Dialog"), "             |\n| Upload in progress                                        | ", React.createElement(_components.a, {
    href: "#toast"
  }, "Toast"), "               |\n| Message to user that comes from different part of the app | ", React.createElement(_components.a, {
    href: "#toast"
  }, "Toast"), "               |\n| Success message for data update on a table cell           | ", React.createElement(_components.a, {
    href: "#toast"
  }, "Toast"), "               |\n| Error message for a form group                            | ", React.createElement(_components.a, {
    href: "#banner"
  }, "Banner"), "             |"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "message-priority",
    style: {
      position: "relative"
    }
  }, "Message Priority", React.createElement(_components.a, {
    href: "#message-priority",
    "aria-label": "message priority permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When deciding what to use for a notification, you have to understand how important the message is to the user and/or the system."), "\n", React.createElement(_components.h3, {
    id: "high",
    style: {
      position: "relative"
    }
  }, "High", React.createElement(_components.a, {
    href: "#high",
    "aria-label": "high permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Messages that persist across the entire app (system or account level)"), "\n", React.createElement(_components.li, null, "Messages that require an interruption and user action to proceed"), "\n", React.createElement(_components.li, null, "Messages that are important for users to see from ST’s perspective - new feature announcement etc."), "\n"), "\n", React.createElement(_components.h3, {
    id: "medium",
    style: {
      position: "relative"
    }
  }, "Medium", React.createElement(_components.a, {
    href: "#medium",
    "aria-label": "medium permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Messages to indicate status of information on a page"), "\n", React.createElement(_components.li, null, "Messages to indicate status of actions taken, including additional information on how to proceed"), "\n"), "\n", React.createElement(_components.h3, {
    id: "low",
    style: {
      position: "relative"
    }
  }, "Low", React.createElement(_components.a, {
    href: "#low",
    "aria-label": "low permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Messages to indicate status of actions taken, without needing additional information or actions"), "\n"), "\n", React.createElement(_components.p, null, "| Name                          | High | Medium | Low |\n| :---------------------------- | :--: | :----: | :-: |\n| ", React.createElement(_components.a, {
    href: "#announcement"
  }, "Announcement"), " |  ✓   |        |     |\n| ", React.createElement(_components.a, {
    href: "#dialog"
  }, "Dialog"), "             |  ✓   |        |     |\n| ", React.createElement(_components.a, {
    href: "#toast"
  }, "Toast"), "               |  ✓   |   ✓    |  ✓  |\n| ", React.createElement(_components.a, {
    href: "#banner"
  }, "Banner"), "             |      |   ✓    |  ✓  |"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "announcement",
    style: {
      position: "relative"
    }
  }, "Announcement", React.createElement(_components.a, {
    href: "#announcement",
    "aria-label": "announcement permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Announcement component can be used for ", React.createElement(_components.strong, null, "high priority"), " notification that is ", React.createElement(_components.strong, null, "persistent across the entire application"), ". Primary use cases are to notify things such as *New Feature *or ", React.createElement(_components.em, null, "System-Wide"), " status such as \"Server is down\" or \"Unable to connect to the Internet.\" Announcements should not stack and only appear one at a time."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Announcement\n\tstatus=\"critical\"\n\ttitle=\"Unable to connect to the Internet\"\n\tactionName=\"Retry\"\n\tonActionClick={() => {}}\n/>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "dialog",
    style: {
      position: "relative"
    }
  }, "Dialog", React.createElement(_components.a, {
    href: "#dialog",
    "aria-label": "dialog permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Dialog is a component that can be used for notification. It can be used for ", React.createElement(_components.strong, null, "high priority"), " notification that ", React.createElement(_components.strong, null, "interrupts user"), " until an action is taken. A primary use case is ", React.createElement(_components.strong, null, "Confirmation of Deletion"), " on critical information."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "frame: true\nnoPadding: true\nheight: 300\n---\n<Dialog\n\t\topen\n\t\ttitle=\"Delete email\"\n\t\tportal={false}\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Delete\"\n\t\tonSecondaryActionClick={() => {}}\n\t\tsecondaryActionName=\"Cancel\"\n\t\tnegative\n>\n\t\tAre you sure you want to delete this email and it scheduling interval?\n</Dialog>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "toast",
    style: {
      position: "relative"
    }
  }, "Toast", React.createElement(_components.a, {
    href: "#toast",
    "aria-label": "toast permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Toast component can be used as ", React.createElement(_components.strong, null, "any priority"), " notifications. The notification is not bound to the current page content, and ", React.createElement(_components.strong, null, "it can notify users about another part of the app"), ". By default, it can ", React.createElement(_components.strong, null, "stack up to 5"), " and also queues after 5 showing visually. A primary use case is ", React.createElement(_components.strong, null, "a notification to show the progress bar.")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Toast.Group portal={false}>\n\t<Toast\n\t\tstatus=\"info\"\n\t\ttitle=\"Import Adjustments\"\n\t\tportal={false}\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"Cancel\"\n\t\tduration={0}\n\t>\n\t\tIn progress\n\t\t<ProgressBar progress={30} small blue className=\"m-t-1\" />\n\t</Toast>\n\t<Toast\n\t\tstatus=\"success\"\n\t\ttitle=\"Successful Import\"\n\t\tonPrimaryActionClick={() => {}}\n\t\tprimaryActionName=\"View Import\"\n\t\tportal={false}\n\t\tduration={0}\n\t>\n\t\t<ProgressBar progress={100} small green className=\"m-t-3\" />\n\t</Toast>\n</Toast.Group>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "banner",
    style: {
      position: "relative"
    }
  }, "Banner", React.createElement(_components.a, {
    href: "#banner",
    "aria-label": "banner permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Banner component is used for contextual(inline) notification for items such as a page or a section on a page. It can be used for ", React.createElement(_components.strong, null, "medium to low priority"), " notification and ideally placed ", React.createElement(_components.strong, null, "above the related content"), ". A primary use case is to show ", React.createElement(_components.strong, null, "error messages for a form"), " group."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Card.Section>\n\t\t<Stack direction=\"column\" spacing={2}>\n\t\t\t<Banner status=\"critical\" title=\"First name is missing\" />\n\t\t\t<Form>\n\t\t\t\t<Form.Group widths=\"equal\">\n\t\t\t\t\t<Form.Input\n\t\t\t\t\t\tlabel=\"First Name\"\n\t\t\t\t\t\tplaceholder=\"Leia\"\n\t\t\t\t\t\terror=\"Enter the first name\"\n\t\t\t\t\t/>\n\t\t\t\t\t<Form.Input label=\"Last Name\" value=\"Organa\" />\n\t\t\t\t</Form.Group>\n\t\t\t\t<Form.Input label=\"Phone Number\" value=\"000-000-0000\" />\n\t\t\t</Form>\n\t\t</Stack>\n\t</Card.Section>\n\t<Card.Section>\n\t\t<ButtonGroup className=\"justify-content-end\">\n\t\t\t<Button>Cancel</Button>\n\t\t\t<Button primary disabled>\n\t\t\t\tSave Contact\n\t\t\t</Button>\n\t\t</ButtonGroup>\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "multiple-banners",
    style: {
      position: "relative"
    }
  }, "Multiple Banners", React.createElement(_components.a, {
    href: "#multiple-banners",
    "aria-label": "multiple banners permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "For the same section with the same statuses, combine them into one Banner."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Card.Section>\n\t\t<Banner\n\t\t\tstatus=\"critical\"\n\t\t\ttitle=\"We're unable to submit the Timesheet\"\n\t\t\tclassName=\"m-b-4\"\n\t\t>\n\t\t\t<ul className=\"p-t-1-i\">\n\t\t\t\t<li>A job must be selected.</li>\n\t\t\t\t<li>Arrived time cannot be before 7:00 AM </li>\n\t\t\t\t<li>Done date cannot be before arrived date. </li>\n\t\t\t</ul>\n\t\t</Banner>\n\t\t<Form>\n\t\t\t<Form.AnvilSelect\n\t\t\t\ttrigger={{ placeholder: \"Select a Job\" }}\n\t\t\t\tlabel=\"Job #\"\n\t\t\t\terror=\"A job must be selected.\"\n\t\t\t\toptions={[]}\n\t\t\t/>\n\t\t\t<Form.Group widths=\"equal\">\n\t\t\t\t<Form.Input label=\"Arrived Time\" value=\"01/01/2020\" />\n\t\t\t\t<Form.Input\n\t\t\t\t\terror=\"Arrived time cannot be before 7:00 AM.\"\n\t\t\t\t\tlabel=\" \"\n\t\t\t\t\tvalue=\"5:15 AM\"\n\t\t\t\t/>\n\t\t\t</Form.Group>\n\t\t\t<Form.Group widths=\"equal\" className=\"m-b-0\">\n\t\t\t\t<Form.Input\n\t\t\t\t\tlabel=\"Done Time\"\n\t\t\t\t\tvalue=\"12/31/2019\"\n\t\t\t\t\terror=\"Done date cannot be before arrived date.\"\n\t\t\t\t/>\n\t\t\t\t<Form.Input label=\" \" value=\"11:00 AM\" />\n\t\t\t</Form.Group>\n\t\t</Form>\n\t</Card.Section>\n\t<Card.Section>\n\t\t<ButtonGroup className=\"justify-content-end\">\n\t\t\t<Button>Cancel</Button>\n\t\t\t<Button primary disabled>\n\t\t\t\tSubmit Timesheet\n\t\t\t</Button>\n\t\t</ButtonGroup>\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.p, null, "For the same section with different statuses, create a Banner for each with higher priority on top (max 2)."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Card>\n\t<Card.Section>\n\t\t<Banner\n\t\t\tstatus=\"critical\"\n\t\t\ttitle=\"We're unable to submit the Timesheet\"\n\t\t\tclassName=\"m-b-1\"\n\t\t>\n\t\t\t<ul className=\"p-t-1-i\">\n\t\t\t\t<li>A job must be selected.</li>\n\t\t\t\t<li>Arrived time cannot be before 7:00 AM </li>\n\t\t\t\t<li>Done date cannot be before arrived date. </li>\n\t\t\t</ul>\n\t\t</Banner>\n\t\t<Banner\n\t\t\ttitle={\n\t\t\t\t<>\n\t\t\t\t\tTip: Checkout our guide on{\" \"}\n\t\t\t\t\t<Link primary>How to manage timesheets</Link>\n\t\t\t\t</>\n\t\t\t}\n\t\t\tclassName=\"m-b-4\"\n\t\t/>\n\t\t<Form>\n\t\t\t<Form.AnvilSelect\n\t\t\t\ttrigger={{ placeholder: \"Select a Job\" }}\n\t\t\t\tlabel=\"Job #\"\n\t\t\t\terror=\"A job must be selected.\"\n\t\t\t\toptions={[]}\n\t\t\t/>\n\t\t\t<Form.Group widths=\"equal\">\n\t\t\t\t<Form.Input label=\"Arrived Time\" value=\"01/01/2020\" />\n\t\t\t\t<Form.Input\n\t\t\t\t\terror=\"Arrived time cannot be before 7:00 AM.\"\n\t\t\t\t\tlabel=\" \"\n\t\t\t\t\tvalue=\"5:15 AM\"\n\t\t\t\t/>\n\t\t\t</Form.Group>\n\t\t\t<Form.Group widths=\"equal\" className=\"m-b-0\">\n\t\t\t\t<Form.Input\n\t\t\t\t\tlabel=\"Done Time\"\n\t\t\t\t\tvalue=\"12/31/2019\"\n\t\t\t\t\terror=\"Done date cannot be before arrived date.\"\n\t\t\t\t/>\n\t\t\t\t<Form.Input label=\" \" value=\"11:00 AM\" />\n\t\t\t</Form.Group>\n\t\t</Form>\n\t</Card.Section>\n\t<Card.Section>\n\t\t<ButtonGroup className=\"justify-content-end\">\n\t\t\t<Button>Cancel</Button>\n\t\t\t<Button primary disabled>\n\t\t\t\tSubmit Timesheet\n\t\t\t</Button>\n\t\t</ButtonGroup>\n\t</Card.Section>\n</Card>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
